.navbarContainer {
  height: 70px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.logoWrap {
  margin: 10px 0;
}

.logo {
  height: 80px;
  width: 80px;
  margin-top: 20px;
}

.menu-icon {
  display: none;
  margin-right: 5px;
}

.navbarContainer .social-icon {
  color: white; /* Set the icon color to white */
  font-size: 24px; /* Set a suitable icon size */
  padding: 10px; /* Add some padding around the icons */
  text-decoration: none; /* Remove underline from links */
}

.navbarContainer .navItems {
  display: flex;
  align-items: center;
  gap: 15px; /* Add spacing between icons */
}


.contact-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: white; /* Set background color */
  border-radius: 50px; /* Make the button rounded */
  border: 2px solid black; /* Outline the button */
  text-align: center;
  color: black;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for emphasis */
}

@media screen and (max-width: 1100px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbarContainer .navbarLogo {
    height: 110px;
    width: 160px;
    background-color: white;
  }
}

