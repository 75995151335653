.homeWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../images/headerimage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.7);
} */

.homeWrapper .content {
  display: flex;
  align-items: center;
  overflow: auto;
  height: 75%;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.homeWrapper .content > div {
  margin-bottom: 20px;
}

.homeWrapper .content .title {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  font-size: 96px;
}

.homeWrapper .content .title .strong {
  color: white;
  margin-right: 10px;
}

.homeWrapper .content .title .discipline {
  color: #ee2737;
  text-align: center;
}

.homeWrapper .content .description {
  font-weight: bold;
  font-size: 28px;
}

@media screen and (max-width: 1104px) {
  .homeWrapper .content .title {
    font-size: 32px;
  }

  .homeWrapper .content .description {
    font-size: 12px;
  }
}
