.reviewPageWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
    grid-template-rows: auto auto; /* Two rows with automatic height */
    gap: 10px; /* Gap between grid items */
    margin: 50px;
}

.reviewPageTitle{
    text-align: center;
    font-weight: bold;
    margin: 20px;
    margin-bottom: 50px;
    font-size: 32px;
}

.reviewTitle {
    display: flex;
    padding-top: 50px;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
}
.review .reviewFotoWrapper {
    display: flex;
    margin-top: 30px;
}

.review .reviewFotoWrapper .fotoLeft, .reviewWrapper .review .reviewFotoWrapper .fotoRight{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review .reviewFotoWrapper .fotoLeft {
    align-items: flex-end;
}
.review .reviewFotoWrapper .fotoRight {
    align-items: flex-start;
}

.reviewFoto {
    width: 95%;
    transition: transform .2s; /* Animation */
}

.reviewFoto:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.review{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1104px) {
    .reviewPageWrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Three columns with equal width */
        grid-template-rows: auto auto; /* Two rows with automatic height */
        gap: 10px; /* Gap between grid items */
        margin: 50px;
    }
}
