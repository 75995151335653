.imgtest {
  height: 200px;
  width: 200px;
}

.serviceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.serviceWrapper .service {
  height: 500px;
  flex: 1;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.serviceWrapper .service .title {
  font-size: 24px;
  font-weight: bold;
}

.serviceWrapper .service .description {
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 1104px) {
  .serviceWrapper {
    flex-direction: column;
  }
  .serviceWrapper .service {
    min-height: 50vh;
    flex: 1;
  }
}
