html,
body {
  font-family: "IBM Plex Sans";
  overflow-x: hidden;
  color: white;
  background-color: #333333;
}

a {
  text-decoration: none;
  color: white;
}

.button {
  background-color: #ad090998;
  border-radius: 10px;
  width: 200px;
  height: 70px;
  color: white;
  border-width: 0px;
  border-color: #ad090998;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
