.services-section {
  background-color: #333333;
  padding: 40px 20px;
}

.services-title {
  color: white;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  position: relative;
}

.underline {
  display: block;
  width: 120px;
  height: 4px;
  background-color: red;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.services-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 75px;
}

.service-card {
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  height: 400px;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  perspective: 1000px;
  transition: transform 0.6s;
  cursor: pointer;
}

.service-card.flipped .service-image {
  transform: rotateY(180deg);
}

.service-card.flipped .service-image-back {
  transform: rotateY(0deg);
}

.service-image,
.service-image-back {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.service-image-back {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-image-back::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter: brightness(100%);
}

.service-info {
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
}

.service-info h1 {
  margin-bottom: 10px;
}

.cta-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #f0f0f0;
}

.service-description {
  color: white;
  text-align: center;
  list-style-type: none;
  padding: 0;
}

.service-description li {
  margin-bottom: 5px;
  color: white;
}

.service-description .additional-info {
  font-size: 16px;
  text-align: center;
  margin: 10px auto;
  max-width: 80%;
}

.checkmark {
  color: green;
}

.services-section.scrolling .service-card {
  pointer-events: none;
}

@media (min-width: 768px) {
  .services-section {
    padding: 80px;
  }

  .services-title {
    font-size: 48px;
  }

  .underline {
    width: 180px;
  }

  .services-grid {
    flex-direction: row;
    justify-content: space-around;
  }

  .service-card {
    width: calc(50% - 20px);
  }
}
