.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: white;
  font-family: Arial, sans-serif;
}

.aboutTitle {
  color: white;
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  margin: 75px 0;
}

.aboutWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  padding: 50px;
  background: #222;
  margin-bottom: 50px;
}

.aboutWrapper.reverse {
  flex-direction: row-reverse;
  margin-bottom: 100px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.textWrapper .title {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.title .firstName {
  margin-right: 10px;
}

.title .lastName {
  color: #ee2737;
}

.textWrapper .box {
  font-size: 18px;
  line-height: 1.5;
  max-width: 600px;
  text-align: center;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.imageWrapper .image {
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .aboutWrapper {
    flex-direction: column;
    width: 95%;
    padding: 20px;
  }

  .aboutWrapper.reverse {
    flex-direction: column;
  }

  .textWrapper,
  .imageWrapper {
    width: 100%;
    padding: 20px;
  }

  .imageWrapper .image {
    max-height: 300px;
  }
}
