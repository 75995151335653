.contactWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 100px;
  box-sizing: border-box;
}

.underline-contact {
  display: block;
  width: 180px;
  height: 4px;
  background-color: #ee2737;
  margin: 0 auto 20px auto;
}

.title {
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.description {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px;
}

.labelTextGroup {
  width: 100%;
  margin-bottom: 12px;
  text-align: left;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  box-sizing: border-box;
}

.submit {
  display: flex;
  justify-content: center;
  width: 100%;
}

button {
  background-color: #ee2737;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .formWrapper {
    padding: 0 10px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
  }
}
